import React from "react";
import {
  Box,
  Container,
  Paper,
  Typography,
  Button,
  makeStyles,
} from "@material-ui/core";
import { Error as ErrorIcon } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: 500,
  },
  icon: {
    fontSize: 64,
    color: theme.palette.error.main,
    marginBottom: theme.spacing(2),
  },
  errorDetails: {
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(2),
    width: "100%",
    wordBreak: "break-word",
  },
  button: {
    marginTop: theme.spacing(3),
  },
}));

/**
 * Fallback component for handling errors in React Error Boundaries.
 *
 * @component
 * @param {object} props - Component props.
 * @param {Error} props.error - The error that was caught.
 * @param {Function} props.resetErrorBoundary - Function to reset the error boundary and retry.
 */
function ErrorFallback({ error, resetErrorBoundary }) {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Container maxWidth="sm">
        <Paper elevation={3} className={classes.paper}>
          <ErrorIcon className={classes.icon} />

          <Typography variant="h5" component="h1" align="center" gutterBottom>
            Oops! Something went wrong.
          </Typography>

          <Typography variant="body1" align="center" color="textSecondary">
            We have been notified and are looking into the issue. Please try
            again in a few moments.
          </Typography>

          {error?.message && (
            <Box className={classes.errorDetails}>
              <Typography variant="caption" color="textSecondary">
                Error details: {error.message}
              </Typography>
            </Box>
          )}

          <Button
            variant="contained"
            color="primary"
            onClick={resetErrorBoundary}
            className={classes.button}
          >
            Try Again
          </Button>
        </Paper>
      </Container>
    </Box>
  );
}

export default ErrorFallback;

import React, { useState } from "react";
import { Button, Card, Container, Grid, Typography } from "@material-ui/core";
import exportToCsv from "../../../../../shared/functions/generateCsv";
import AgentReportDialog from "./agent-report-dialog.shared";
import sqlToStandardDate from "../../../../../shared/functions/sqlToStandardDate";
import Loading from "../../../../../Loading";

/**
 * A component for displaying a line item with a specified value.
 *
 * @param {object} props - The component props.
 * @param {string} props.value - The value to display in the line item.
 * @param {string} props.label - The label associated with the value.
 * @param {number} props.width - Max width is 12 - this value will determine the width by deviding the number into 12
 *
 * @returns {React.ReactElement} A grid component displaying the value.
 */
function LineItem({ value = "", label = "", width = 4 }) {
  return (
    <Grid item xs={width} spacing={1} direction="row">
      <Typography
        variant="body2"
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        <b>{label}:</b> {value}
      </Typography>
    </Grid>
  );
}

/**
 * AgentReport is a component that displays a report for agents with order statistics, a table of order data,
 * and the ability to download the report as a CSV file. It also provides a modal to view detailed customer information.
 *
 * @component
 *
 * @param {object} props - The properties passed to the component.
 * @param {Array<object>} props.data - The data for the report, typically an array of order objects.
 * @param {boolean} props.loading - A flag indicating if the report is loading data.
 * @param {Array<{label: string, value: number}>} props.tiles - An array of objects representing summary statistics
 *        to be displayed as tiles, where each object contains a `label` and a `value`.
 *
 * @returns {React.Component} - The rendered component.
 */
function AgentReport({ data = [], loading = false, tiles = [] }) {
  // State to control the visibility of the customer detail modal
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  // State to hold the data of the selected customer for display in the modal
  const [customerData, setCustomerData] = useState([]);

  // Map over the tiles array to create the display tiles for summary statistics
  const tileMap = tiles.map(item => (
    <Grid
      xs={6}
      lg={3}
      item
      container
      direction="column"
      alignItems="center"
      key={item.label}
      spacing={1}
    >
      <Grid item>
        <Typography variant="caption">{item.label}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="h6">{item.value}</Typography>
      </Grid>
    </Grid>
  ));

  /*
  0 - Order placed - White
  1 - Order paid - #ff9a2b
  2 - order activated - #10284b
  3 - order commission paid - #3bc3a9
  4 - Order re-activated - #ec4d1f

  1 commission paid = order_status.
   */

  /**
   * Determines the status of an order based on its lifecycle dates and onboarding status.
   *
   * @param {object} params - The parameters for determining the status.
   * @param {string|null} params.orderPaidDate - The date when the order was paid.
   * @param {string|null} params.activationDate - The date when the order was activated.
   * @param {string|null} params.commissionDate - The date when the commission was paid.
   * @param {string} params.onboardingStatus - The onboarding status of the order.
   *
   * @returns {number} - Returns 4 for reactivation, 3 for commission paid,
   *                     2 for activated, 1 for paid, and 0 for placed.
   */

  const determineStatus = ({
    orderPaidDate,
    activationDate,
    commissionDate,
    onboardingStatus,
  }) => {
    if (onboardingStatus === "Reactivation") {
      return { status: 4, color: "#ec4d1f" };
    }

    // if (commissionDate) return { status: 3, color: "#3bc3a9" };
    if (commissionDate) return { status: 3, color: "#10284b" };

    // if (activationDate) return { status: 2, color: "#10284b" };
    if (activationDate) return { status: 2, color: "#3bc3a9" };

    if (orderPaidDate) return { status: 1, color: "#ff9a2b" };

    return { status: 0, color: "white" };
  };

  const dataMap =
    data && data[0] ? (
      data.map(row => {
        const lifeCycleStatus = determineStatus({
          orderPaidDate: row.order_paid_date_1,
          activationDate: row.order_activation_date_2,
          commissionDate: row.order_status?.agent_activation_comm_paid_date_1,
          onboardingStatus: row.customer_onboarding_status_0,
        });

        return (
          <Grid
            item
            key={row.order_number_1}
            style={{
              maxWidth: "95%",
            }}
          >
            <Card
              style={{
                border: "2px solid",
                borderColor: `${lifeCycleStatus.color}`,
                borderRadius: "16px",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                paddingBottom: "0.5rem",
                paddingTop: "0.5rem",
              }}
              onClick={() => {
                setShowCustomerModal(true);
                setCustomerData(row);
              }}
            >
              <Container>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="flex-start"
                  spacing={1}
                >
                  <Grid item xs={12} lg={12}>
                    <Typography variant="h6">{row.order_number_1}</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={12}
                    container
                    direction="row"
                    spacing={1}
                    justifyContent="flex-start"
                  >
                    <LineItem
                      label="CRT"
                      value={sqlToStandardDate(row.order_created_date_1)}
                    />
                    <LineItem
                      label="PD"
                      value={sqlToStandardDate(row.order_paid_date_1)}
                    />
                    <LineItem
                      label="ACT"
                      value={sqlToStandardDate(row.order_activation_date_2)}
                    />
                    <LineItem
                      label="CUST"
                      width={12}
                      value={
                        row.customer_details_name_0
                          ? row.customer_details_name_0
                          : "Not available"
                      }
                    />
                    <LineItem
                      label="ADDR"
                      width={12}
                      value={
                        row.customer_details_address_0
                          ? row.customer_details_address_0
                          : "Not available"
                      }
                    />
                  </Grid>
                </Grid>
              </Container>
            </Card>
          </Grid>
        );
      })
    ) : (
      <Grid item>
        <Typography>No data to display</Typography>
      </Grid>
    );

  return (
    <Loading loading={loading}>
      <Container>
        <Grid container direction="column" spacing={2}>
          <Grid container item direction="row">
            {tileMap}
          </Grid>
          <Grid
            container
            item
            xs={12}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item xs={12} md={10}>
              <Typography>
                Click on one of the orders to display more Information
              </Typography>
            </Grid>
            <Grid item xs={12} md={2} justifyContent="flex-end">
              <Button
                color="primary"
                variant="contained"
                style={{ marginTop: 20, marginBottom: 20 }}
                onClick={() => exportToCsv(data, "Agent Report")}
                fullWidth
              >
                Download
              </Button>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            direction="column"
            style={{
              padding: 0,
              paddingBottom: "24px",
            }}
          >
            {data ? (
              dataMap
            ) : (
              <Grid item>
                <Typography>
                  Error fetching your data. The development team is looking into
                  the problem.
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        <AgentReportDialog
          open={showCustomerModal}
          customerData={customerData}
          toggleDialog={() => {
            setShowCustomerModal(prev => !prev);
          }}
        />
      </Container>
    </Loading>
  );
}

export default AgentReport;

import React from "react";

import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  Typography,
  Grid,
} from "@material-ui/core";

/**
 * A component that renders a labeled grid line.
 *
 * @param {object} props - The component props.
 * @param {string} props.label - The label to display on the grid line.
 * @param {string} props.value - The value to display associated with the label.
 *
 * @returns {React.ReactElement} The rendered grid line component.
 */
function GridLine({ label, value }) {
  return (
    <Grid item container xs={12}>
      <Grid item xs={12} md={4}>
        <Typography variant="subtitle2">{label}</Typography>
      </Grid>
      <Grid item xs={12} md={8}>
        <Typography variant="body2">{value}</Typography>
      </Grid>
    </Grid>
  );
}

/**
 * A dialog component that displays information about a customer.
 *
 * @param {object} props - The component props.
 * @param {object} props.customerData - The customer data object.
 * @param {boolean} props.open - Whether the dialog is open.
 * @param {Function} props.toggleDialog - Function to toggle the dialog open state.
 *
 * @returns {React.ReactElement} The rendered dialog component.
 */
function AgentReportDialog({ customerData = {}, open = false, toggleDialog }) {
  return (
    <Dialog open={open}>
      <DialogTitle>
        {customerData?.order_number_1
          ? customerData?.order_number_1
          : "Order detail"}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {customerData.customer_details_address_0 && (
            <GridLine
              label="Address:"
              value={customerData.customer_details_address_0}
            />
          )}
          {customerData.customer_details_name_0 && (
            <GridLine
              label="Contact Name:"
              value={customerData.customer_details_name_0}
            />
          )}
          {customerData?.customer_details_phone_0?.length === 9 && (
            <GridLine
              label="Contact Number:"
              value={`0${customerData.customer_details_phone_0}`}
            />
          )}

          {customerData.order_created_date_1 && (
            <GridLine
              label="Order Created Date:"
              value={customerData.order_created_date_1}
            />
          )}
          {customerData.order_paid_date_1 && (
            <GridLine
              label="Payment Received Date:"
              value={customerData.order_paid_date_1}
            />
          )}
          {customerData.order_installation_date_1 && (
            <GridLine
              label="Order Installed Date:"
              value={customerData.order_installation_date_1}
            />
          )}
          {customerData.order_activation_date_2 && (
            <GridLine
              label="Order Activation Date:"
              value={customerData.order_activation_date_2}
            />
          )}
          {customerData.order_status?.agent_activation_comm_paid_date_1 && (
            <GridLine
              label="Commission Paid Date:"
              value={
                customerData.order_status.agent_activation_comm_paid_date_1
              }
            />
          )}
          {customerData.customer_onboarding_status_0 && (
            <GridLine
              label="Activation Status:"
              value={customerData.customer_onboarding_status_0}
            />
          )}
          {customerData.customer_current_status_0 && (
            <GridLine
              label="Current Status:"
              value={customerData.customer_current_status_0}
            />
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          alignContent="center"
          justifyContent="flex-end"
          spacing={2}
        >
          {customerData?.customer_details_phone_0?.length === 9 && (
            <Grid item>
              <Button
                variant="text"
                color="primary"
                onClick={() => {
                  window.open(
                    `https://wa.me/+27${customerData.customer_details_phone_0}`,
                    "_blank"
                  );
                }}
              >
                Whatsapp customer
              </Button>
            </Grid>
          )}
          <Grid item>
            <Button
              variant="text"
              color="secondary"
              type="button"
              onClick={toggleDialog}
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default AgentReportDialog;
